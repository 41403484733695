import { AsyncComponent } from 'vue'

export enum CTABoxVersion {
    Default = 'default',
    Banner = 'banner',
}

export const CTABoxVersionRegistry: Record<string, AsyncComponent> = {
  [CTABoxVersion.Default]: () => import(
    /* webpackChunkName: "modules/CTABoxDefault" */
    './versions/Default/Default.vue'
  ),
  [CTABoxVersion.Banner]: () => import(
    /* webpackChunkName: "modules/CTABoxBanner" */
    './versions/Banner/Banner.vue'
  )
}
