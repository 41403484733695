






import { AsyncComponent } from 'vue'
import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { UiMarkdown } from '../../partials/UiMarkdown'

import { CTABoxModule } from '../CTABox.contracts'
import { CTABoxVersion, CTABoxVersionRegistry } from '../CTABox.config'
import { logger } from '../../../support'

@Component({ name: 'CTABoxModuleUi', components: { UiMarkdown } })
export class CTABoxModuleUi extends AbstractModuleUi<CTABoxModule> {
  public get component (): AsyncComponent | undefined {
    const component = CTABoxVersionRegistry[this.version]

    if (typeof component === 'undefined') {
      logger(
          `CTABoxModuleUi.ui.component(): There's no Vue component associated with the [${this.data.version}] CTABoxModuleUi!`,
          'error'
      )
      return
    }

    return component
  }

  public get version () {
    if (!this.data.version) {
      return CTABoxVersion.Default
    }

    return this.data.version as CTABoxVersion
  }
}

export default CTABoxModuleUi
