






import { Component } from 'vue-property-decorator'

import CTABoxModuleUi from '../../../../../modules/CTABox/ui/CTABox.ui.vue'

import { AbstractMegaMenuAddon } from './abstract'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<CTABoxAddon>({
  name: 'CTABoxAddon',
  components: { CTABoxModuleUi }
})
export class CTABoxAddon extends AbstractMegaMenuAddon {}

export default CTABoxAddon
